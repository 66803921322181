export const videos = [
    {
        id: 18,
        category: 'video',
        title: 'Nick White || COO of Celestia Labs: Modular Blockchains',
        image: 'resources/nick-logan-thumbnail.png',
        url: 'https://www.youtube.com/watch?v=5rxPKG_x-lE',     
    },{
        id: 17,
        category: 'video',
        title: 'DISRUPTORS: Modular Blockchains for Sovereign Communities Mustafa Al-Bassam',
        image: 'resources/mustafa-delphi-thumbnail.png',
        url: 'https://www.youtube.com/watch?v=PeqtTwMDAQQ',     
    },{
        id: 16,
        category: 'video',
        title: 'Building the Multiverse with Nick White',
        image: 'resources/nick-bankless.png',
        url: 'https://www.youtube.com/watch?v=bmSU3cZhe3U',     
    },{
        id: 15,
        category: 'video',
        title: 'Axelar Tech Talks with Celestia',
        image: 'resources/evan-axelar.png',
        url: 'https://www.youtube.com/watch?v=3bxzSM02qvI',     
    },{
        id: 14,
        category: 'video',
        title: 'Celestia: The World’s First Modular Blockchain Network',
        image: 'resources/John-Delphi-podcast.png',
        url: 'https://www.youtube.com/watch?v=-EYRtqsTkZ4&t=2214s',     
    },{
        id: 13,
        category: 'video',
        title: 'Breaking Down Celestia, The First Modular Blockchain Network (Aditi)',
        image: 'resources/Aditi-DAOn-interview.png',
        url: 'https://www.youtube.com/watch?v=XLldSScJ4gE',     
    },{
        id: 12,
        category: 'video',
        title: 'Secure Off-chain Data Availability for Rollups',
        image: 'resources/John-Adler-ETH-Denver.png',
        url: 'https://www.youtube.com/watch?v=f88tGRBimmE',     
    },{
        id: 11,
        category: 'video',
        title: 'The Rollup Developer Experience panel',
        image: 'resources/Aditi-Rollup-Panel.png',
        url: 'https://www.youtube.com/watch?v=Tgk7eXUCgYk',     
    },{
        id: 10,
        category: 'video',
        title: 'Celestia Deep Dive: Data Availability Sampling, CEVMOS, Rollups & More',
        image: 'resources/Celestia-cryptocito.png',
        url: 'https://www.youtube.com/watch?v=3-kIa-r8Twc',     
    },{
        id: 9,
        category: 'video',
        title: 'Live with Nick from Celestia',
        image: 'resources/placeholders/video.png',
        url: 'https://www.twitch.tv/videos/1271355438',
    },{
        id: 8,
        category: 'video',
        title: 'Ismail Khoffi - Cofounder of Celestia Speaks on Shared Security in the blockchain',
        image: 'resources/Ismail-Cosmoverse-thumbnail.png',
        url: 'https://www.youtube.com/watch?v=MabYtuTncW0',
    },{
        id: 1,
        category: 'video',
        title: 'Celestia: A pluggable consensus and data availability layer',
        image: 'resources/introduction-talk.png',
        url: 'https://www.youtube.com/watch?v=r1Ftl24xu6Q',
    },{
        id: 2,
        category: 'video',
        title: 'Celestia: Data Availability Layer for Cosmos Network',
        image: 'resources/placeholders/video.png',
        url: 'https://www.youtube.com/watch?v=W6Q8D-18EAk',
    },{
        id: 3,
        category: 'video',
        title: 'FC21: Fraud and Data Availability Proofs: Detecting Invalid Blocks in Light Clients\n',
        image: 'resources/placeholders/video.png',
        url: 'https://www.youtube.com/watch?v=1ziYTV4K2tA',
    },{
        id: 4,
        category: 'video',
        title: 'Whiteboard Series with NEAR | Ep: 40 Part 1, John Adler from Celestia (previously Lazy Ledger)',
        image: 'resources/Celestia-Near-whiteboard-part1.png',
        url: 'https://www.youtube.com/watch?v=jM-om3AqH94',
    },{
        id: 5,
        category: 'video',
        title: 'Whiteboard Series with NEAR | Ep: 40 Part 2, John Adler from Celestia (previously Lazy Ledger)',
        image: 'resources/Celestia-Near-whiteboard-part2.png',
        url: 'https://www.youtube.com/watch?v=MZQMObV3890',
    },{
        id: 6,
        category: 'video',
        title: 'Conversations with Jack ft. Mustafa Al-Bassam - S1E15 - by Sommelier Finance & OmniFlix Network',
        image: 'resources/Conversations-with-Jack.png',
        url: 'https://www.youtube.com/watch?v=hacZ4nX_Jjg',
    },{
        id: 7,
        category: 'video',
        title: 'Interchain Conversations II-Pluggable Consensus & Data Availability Layer For Cosmos Zones & Rollups',
        image: 'resources/placeholders/video.png',
        url: 'https://www.youtube.com/watch?v=OWzVgRRLjMg',
    },
]
